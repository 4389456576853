import React from "react";
import cn from "classnames";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";

function DateTimePicker({ datetime, onChange, dateError, dateFocusHandler }) {
  if (navigator.platform !== "MacIntel") {
    return (
      <input
        value={
          datetime === null
            ? ""
            : new Date(
                new Date(datetime).setHours(
                  new Date(datetime).getHours() +
                    Math.abs(new Date().getTimezoneOffset() / 60)
                )
              )
                .toJSON()
                .slice(0, 16)
        }
        onFocus={dateFocusHandler}
        type="datetime-local"
        onChange={(e) => {
          if (e.target.value !== "") onChange(new Date(e.target.value));
        }}
        className={cn("date_input", {
          input_warning: dateError,
        })}
      />
    );
  } else {
    registerLocale("ru", ru);
    return (
      <DatePicker
        onFocus={dateFocusHandler}
        locale="ru"
        selected={datetime}
        className={cn("date_input", {
          input_warning: dateError,
        })}
        onChange={(date) => onChange(date)}
        timeInputLabel="Время:"
        dateFormat="dd.MM.yyyy HH:mm"
        showTimeInput
      />
    );
  }
}

export default DateTimePicker;
