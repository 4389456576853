import React, { createRef } from "react";
// import DatePicker, { registerLocale } from "react-datepicker";
// import ru from "date-fns/locale/ru";
// import "react-datepicker/dist/react-datepicker.css";
import Axios from "axios";
import {
  CompanySelect,
  TypeSelect,
  CharacterSelect,
  ContractsSelect,
  ObjectSelect,
  DateTimePicker,
} from "../Components";
import cn from "classnames";
class AddWork extends React.Component {
  constructor(props) {
    super();
    // registerLocale("ru", ru);
    this.state = {
      datestartTooltip: false,
      descriptionTooltip: false,
      datestart: null,
      dateend: null,
      company: null,
      object: null,
      typeWork: null,
      characterWork: null,
      contract: null,
      errorones: "",
      // isLoadingContract: false,
      // isLoadingObject: false,
      objMustHave: false,
      errors: {
        dateStart: true,
        dateEnd: false,
        type: false,
        character: false,
        reason: false,
        company: false,
        contract: false,
        object: false,
        description: true,
      },
    };
    this.causeComplete = createRef();
    this.isDoneCheck = createRef();
    // this.endTimeRef = createRef();
    // this.startTimeRef = createRef();
    this.commentRef = createRef();
    this.descriptionRef = createRef();
    this.workFreeRef = createRef();
    this.saveWorkRef = createRef();
  }

  componentDidMount() {
    let workId = null;
    if (
      this.props.location.pathname === "/edit-work" ||
      this.props.location.pathname === "/edit-work-back-to-journal"
    ) {
      if (typeof this.props.location.state !== "undefined") {
        workId = this.props.location.state.id;
      } else {
        if (this.props.location.search.match(/\d{1,}/) !== null)
          workId = this.props.location.search.match(/\d{1,}/)[0];
      }
      if (workId !== null)
        Axios.get(`${process.env.REACT_APP_BACKEND_URL}/work/work/${workId}?format=json`)
          .then((response) => {
            console.log(response.data);
            let errorEdit = {};
            let workDet = {};
            workDet.characterWork = response.data.character;
            if (!response.data.character) errorEdit.character = true;
            this.commentRef.current.value = response.data.comment;
            workDet.object = response.data.companyobject;
            if (!response.data.companyobject) errorEdit.object = true;
            workDet.contract = response.data.contractobject;
            if (!response.data.contractobject) errorEdit.contract = true;
            if (response.data.dateend)
              workDet.dateend = new Date(response.data.dateend);
            else errorEdit.dateEnd = true;
            workDet.datestart = new Date(response.data.datestart);
            errorEdit.dateStart = false;
            this.descriptionRef.current.value = response.data.description;
            if (response.data.description) errorEdit.description = false;
            this.descriptionRef.current.id = response.data.id;
            workDet.typeWork = response.data.type;
            if (!response.data.type) errorEdit.type = true;
            this.isDoneCheck.current.checked = response.data.workend;
            if (!response.data.workend && !response.data.workenddescription)
              errorEdit.reason = true;
            this.causeComplete.current.value = response.data.workenddescription;
            this.workFreeRef.current.checked = response.data.workfree;
            workDet.company = response.data.company;
            workDet.errorones = response.data.errorones;
            if (!response.data.company) errorEdit.company = true;
            this.setState((state) => ({
              ...workDet,
              isLoadingContract: true,
              isLoadingObject: true,
              errors: { ...state.errors, ...errorEdit },
            }));
          })
          .catch((error) => {
            console.log(error);
            alert(error.response.data[Object.keys(error.response.data)[0]]);
          });
    }
    if (this.props.location.pathname === "/add-remote-work") {
      Axios.get(`${process.env.REACT_APP_BACKEND_URL}/work/worktype`)
        .then((r) => {
          this.setState((state) => ({
            datestart: new Date(),
            typeWork: r.data.find((x) => x.name === "Удаленная работа").id,
            errors: { ...state.errors, dateStart: false },
          }));
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  setErrors = (e) => {
    this.setState({ errors: e });
  };

  // componentDidUpdate(k,prevstate) {
  //   console.log(prevstate);
  // }

  workIsCompleted = (event) => {
    if (event.target.checked) {
      this.causeComplete.current.value = "Работа выполнена";
      this.setState((state) => ({
        errors: { ...state.errors, reason: false },
      }));
    } else {
      this.causeComplete.current.value = "";
      this.setState((state) => ({
        errors: { ...state.errors, reason: true },
      }));
    }
  };

  showErrorReason = (e) => {
    if (e.target.value === "")
      this.setState((state) => ({
        errors: { ...state.errors, reason: true },
      }));
    else
      this.setState((state) => ({
        errors: { ...state.errors, reason: false },
      }));
  };

  showErrorDescription = (e) => {
    if (e.target.value === "" && !this.state.errors.description)
      this.setState((state) => ({
        errors: { ...state.errors, description: true },
      }));
    if (e.target.value !== "" && this.state.errors.description)
      this.setState((state) => ({
        errors: { ...state.errors, description: false },
      }));
  };

  checkError = (obj) => {
    let val = true;
    Object.keys(obj).forEach((element) => {
      val = val && obj[element];
    });
    return val;
  };
  selectedCompanyToLS(workItem) {
    let companysJSON = localStorage.getItem("companysSelected");
    if (companysJSON === null) {
      localStorage.setItem(
        "companysSelected",
        JSON.stringify({ [workItem.company]: 1 })
      );
    } else {
      let companys = JSON.parse(companysJSON);
      if (!companys.hasOwnProperty(workItem.company)) {
        companys[workItem.company] = 1;
        localStorage.setItem("companysSelected", JSON.stringify(companys));
      } else {
        companys[workItem.company] = companys[workItem.company] + 1;
        localStorage.setItem("companysSelected", JSON.stringify(companys));
      }
    }
  }

  selectedContractToLS(workItem) {
    let contractsJSON = localStorage.getItem("contractsSelected");
    if (contractsJSON === null) {
      localStorage.setItem(
        "contractsSelected",
        JSON.stringify({ [workItem.contractobject]: 1 })
      );
    } else {
      let contracts = JSON.parse(contractsJSON);
      if (!contracts.hasOwnProperty(workItem.contractobject)) {
        contracts[workItem.contractobject] = 1;
        localStorage.setItem("contractsSelected", JSON.stringify(contracts));
      } else {
        contracts[workItem.contractobject] =
          contracts[workItem.contractobject] + 1;
        localStorage.setItem("contractsSelected", JSON.stringify(contracts));
      }
    }
  }
  // ADD_or_SAVE_WORK
  saveWork = (e) => {
    if (this.saveWorkRef.current.disabled === false) {
      this.saveWorkRef.current.disabled = true;
      e.preventDefault();
      let workItem = {
        datestart:
          this.state.datestart === null
            ? ""
            : this.state.datestart.toISOString(),
        dateend:
          this.state.dateend === null ? "" : this.state.dateend.toISOString(),
        type: this.state.typeWork,
        character: this.state.characterWork,
        workend: e.target.elements.workIsDone.checked,
        workenddescription: e.target.elements.reason.value,
        workfree: e.target.elements.present.checked,
        company: this.state.company,
        contractobject: this.state.contract,
        companyobject: this.state.object,
        description: e.target.elements.discription.value,
        comment: e.target.elements.comment.value,
      };
      if (workItem.dateend === "") workItem.dateend = null;
      let errorOptions = {
        dateStart: !!workItem.datestart,
        dateEnd: !!workItem.dateend,
        type: !!workItem.type,
        character: !!workItem.character,
        reason: !(
          !workItem.workenddescription && !this.isDoneCheck.current.checked
        ),
        company: !!workItem.company,
        contract: !!workItem.contractobject,
        object: !(!workItem.companyobject && this.state.objMustHave),
        description: !!workItem.description,
      };

      workItem.full = this.checkError(errorOptions);
      if (!errorOptions.dateStart) {
        this.setState({ datestartTooltip: true });
        window.scroll(0, 0);
        this.saveWorkRef.current.disabled = false;
      } else if (!errorOptions.description) {
        this.setState({ descriptionTooltip: true });
        this.saveWorkRef.current.disabled = false;
      } else {
        if (errorOptions.company) {
          this.selectedCompanyToLS(workItem);
        }
        if (errorOptions.contract) {
          this.selectedContractToLS(workItem);
        }
        if (
          this.props.location.pathname === "/add-work" ||
          this.props.location.pathname === "/add-remote-work"
        ) {
          Axios.post(`${process.env.REACT_APP_BACKEND_URL}/work/work`, workItem)
            .then((response) => {
              // if (response.statusText === "Created")
              // if (this.props.location.pathname === "/add-remote-work"){
              //   window.location.href = "/arrives";
              // }
              // else{
              //   window.location.href = "/works";
              // }
              this.props.history.goBack()
              // 
              this.saveWorkRef.current.disabled = false;
            })
            .catch(function (error) {
              console.log(error.response);
              alert(error.response.data[Object.keys(error.response.data)[0]]);
            });
        } else if (
          this.props.location.pathname === "/edit-work" ||
          this.props.location.pathname === "/edit-work-back-to-journal"
        ) {
          workItem.errorones = "";
          Axios.put(
            `${process.env.REACT_APP_BACKEND_URL}/work/work/${this.descriptionRef.current.id}`,
            workItem
          )
            .then((response) => {
              console.log(response);
              // if (response.statusText === "OK")
              // if (
              //   this.props.location.pathname === "/edit-work-back-to-journal"
              // ) {
              //   window.location.href = "/arrives";
              // } else if (this.props.location.pathname === "/edit-work") {
              //   window.location.href = "/works";
              // }
              this.props.history.goBack()
              this.saveWorkRef.current.disabled = false;
            })
            .catch(function (error) {
              console.log(error);
              alert(error.response.data[Object.keys(error.response.data)[0]]);
            });
        }
      }
    }
  };

  cancelHandler = () => {
    // if (this.props.location.pathname === "/edit-work-back-to-journal" ||
    // this.props.location.pathname === "/add-remote-work") {
    //   window.location.href = "/arrives";
    // } else if (
    //   this.props.location.pathname === "/edit-work" ||
    //   this.props.location.pathname === "/add-work"
    // ) {
    //   window.location.href = "/works";
    // }
    this.props.history.goBack()
  };

  dateStartOnFocus = () => {
    this.setState({ datestartTooltip: false });
  };

  setDate = (date) => {
    if (date[Object.keys(date)[0]] === null)
      this.setState((state) => ({
        ...date,
        errors: {
          ...state.errors,
          [Object.keys(date)[0] === "datestart"
            ? "dateStart"
            : "dateEnd"]: true,
        },
      }));
    else
      this.setState((state) => ({
        ...date,
        errors: {
          ...state.errors,
          [Object.keys(date)[0] === "datestart"
            ? "dateStart"
            : "dateEnd"]: false,
        },
      }));
  };

  descriptionOnFocus = () => {
    this.setState({ descriptionTooltip: false });
  };
  render() {
    return (
      <form className="work-form" onSubmit={this.saveWork}>
        <div className="row">
          <div className="work-time col-lg-6 col-xs-12">
            <label htmlFor="startTime">Начало:</label>
            <div className="date-time-picker">
              <DateTimePicker
                datetime={this.state.datestart}
                dateFocusHandler={this.dateStartOnFocus}
                onChange={(date) => this.setDate({ datestart: date })}
                dateError={this.state.errors.dateStart}
              />
              {this.state.datestartTooltip && (
                <div className="popup-edit">Заполните это поле.</div>
              )}
            </div>
            <label htmlFor="endTime">Конец:</label>
            <div className="date-time-picker2">
              <DateTimePicker
                datetime={this.state.dateend}
                onChange={(date) => this.setDate({ dateend: date })}
                dateError={this.state.errors.dateEnd}
              />
            </div>
          </div>
          <div className="col-lg-6 col-xs-12 errorones">
            {this.state.errorones}
          </div>
        </div>
        <hr />
        <TypeSelect
          changeTypeWork={(e) => this.setState({ typeWork: e })}
          selectValue={this.state.typeWork}
          showError={this.setErrors}
          errors={this.state.errors}
        />
        <hr />
        <CharacterSelect
          onChangeCharacter={(e) => this.setState({ characterWork: e })}
          errors={this.state.errors}
          setErrors={this.setErrors}
          selectValue={this.state.characterWork}
        />

        <hr />
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <input
                name="workIsDone"
                ref={this.isDoneCheck}
                onChange={this.workIsCompleted}
                type="checkbox"
              />
            </div>
          </div>
          <input
            name="reason"
            ref={this.causeComplete}
            type="text"
            className={cn("form-control", {
              input_warning: this.state.errors.reason,
            })}
            placeholder="Работа выполнена(причина)?"
            onChange={this.showErrorReason}
          />
        </div>
        <div>
          <input
            ref={this.workFreeRef}
            type="checkbox"
            name="present"
            className="mr-2"
          />
          <label htmlFor="present">Работу не предъявлять контрагенту</label>
        </div>
        <hr />
        <CompanySelect
          onCompanyChange={(e) =>
            this.setState({ company: e, object: null, contract: null })
          }
          selectValue={this.state.company}
          showError={this.setErrors}
          errors={this.state.errors}
        />
        <hr />
        <ContractsSelect
          onContractChange={(e) => this.setState({ contract: e })}
          selectValue={this.state.contract}
          idcompany={this.state.company}
          objIsMustHave={(e) => this.setState({ objMustHave: e })}
          showError={this.setErrors}
          errors={this.state.errors}
          isLoad={this.state.isLoadingContract}
          setIsLoad={(e) => this.setState({ isLoadingContract: e })}
        />
        <hr />
        {(this.state.objMustHave || !!this.state.object) && (
          <ObjectSelect
            onObjectChange={(e) => this.setState({ object: e })}
            selectValue={this.state.object}
            idcompany={this.state.company}
            showError={this.setErrors}
            errors={this.state.errors}
            isLoad={this.state.isLoadingObject}
            setIsLoad={(e) => this.setState({ isLoadingObject: e })}
          >
            <hr />
          </ObjectSelect>
        )}
        <div id="workDiscriptoion">
          <label htmlFor="discription">Описание работ</label>
          <div className="error-form-tip">
            <textarea
              onFocus={this.descriptionOnFocus}
              ref={this.descriptionRef}
              type="text"
              className={cn("form-control", {
                input_warning: this.state.errors.description,
              })}
              name="discription"
              onChange={this.showErrorDescription}
            ></textarea>
            {this.state.descriptionTooltip && (
              <div ref={this.descriptionPopup} className="popup-edit">
                Заполните это поле.
              </div>
            )}
          </div>
        </div>
        {/* {!errors.description && (
        <label className="error-label">Заполните описание работ</label>
      )} */}
        <hr />

        <div id="problemDescription">
          <label htmlFor="comment">Комментарий</label>
          <textarea
            ref={this.commentRef}
            type="text"
            className="form-control"
            name="comment"
          ></textarea>
        </div>

        <button
          ref={this.saveWorkRef}
          type="submit"
          className="btn btn-save btn-login mb-2"
        >
          Сохранить
        </button>
        <button
          type="button"
          onClick={this.cancelHandler}
          className="btn btn-login cancel-modal mb-2 ml-3"
        >
          Отменить
        </button>
      </form>
    );
  }
}

export default AddWork;
