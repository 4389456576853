import React from "react";
import ReactDOM from "react-dom";
import { configure } from "axios-hooks";
import Axios from "axios";
import Cookies from "js-cookie";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";

let token = Cookies.get("token");
if (token) {
  const axios = Axios.create({
    headers: { Authorization: `Token ${token}` },
  });
  configure({ axios });
  Axios.defaults.headers.common['Authorization'] = `Token ${token}`;
}

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);
