import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
// import SelectSearch from "react-select-search";
// import axios from "axios";
// import "../modal.css";
// import {Header} from '../Components';
const Test = () => {
  const [date, setDate] = useState(new Date());
  React.useEffect(() => {
    console.log(date);
  }, [date]);
  return (
    <DatePicker
      minDate={new Date()}
      minTime={new Date()}
      selected={date}
      onChange={(date) => setDate(date)}
      timeInputLabel="Time:"
      dateFormat="MM/dd/yyyy HH:mm"
      showTimeInput
    />
  );
};

export default Test;
