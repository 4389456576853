import React from "react";
import useAxios from "axios-hooks";
import SelectSearch from "react-select-search";
import cn from 'classnames'

function TypeSelect({ changeTypeWork, showError, errors, selectValue }) {
  const changeSelect = (e) => {
    showError({ ...errors, type: false });
    changeTypeWork(e);
  };
  let options = [];
  const [{ data, loading, error }] = useAxios(
    `${process.env.REACT_APP_BACKEND_URL}/work/worktype/?format=json`
  );
  if (!loading && !error) {
    options = data?.map((r) => {
      return { value: r.id, name: r.name };
    });
  }
  if (error) {
    options = [
    ];
  }
  return (
    <div>
      <label htmlFor="workType">Тип работ</label>
      <SelectSearch
        options={options}
        onChange={changeSelect}
        value={selectValue}
        placeholder="..."
        className={cn("select-search",{input_warning: errors.type})}
      />
    </div>
  );
}

export default TypeSelect;
