import React from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

import { Login, Header } from "../Components";

import statusLogo from "../assets/img/status.png";
import tasksLogo from "../assets/img/tasks.png"

function Menu() {
  if (Cookies.get("token"))
    return (
      <>        
        <div className="list-group menu-list-group">
        <Header title="Учет рабочего времени" mainPage={true} />
          {/* <div className="list-group-item list-group-item-action active">
          Добрый день! Выберите действие...
        </div> */}
          {/* <Link to="/tasks" className="list-group-item list-group-item-action">
          <i className="fa fa-calendar fa-5x" aria-hidden="true"></i>
          <h6>Задачи</h6>
        </Link> */}
          <Link to="/status" className="list-group-item list-group-item-action">
            <img style={{ height: "80px" }} src={statusLogo} alt="..." />
            <h6>Занятость сотрудников</h6>
          </Link>
          <Link
            to="/arrives"
            className="list-group-item list-group-item-action"
          >
            <i className="fa fa-pencil-square-o fa-5x" aria-hidden="true"></i>
            <h6>Журнал передвижений</h6>
          </Link>
          <Link to="/works" className="list-group-item list-group-item-action">
            <i className="fa fa-tasks fa-5x" aria-hidden="true"></i>
            <h6>Работы</h6>
          </Link>
          <a 
          // href={`${process.env.REACT_APP_BACKEND_URL}/work/tasks/`} 
          href="https://worktask.avpkirov.ru/" 
          target="_blank" rel="noopener noreferrer" className="list-group-item list-group-item-action">
            <img style={{ height: "80px" }} src={tasksLogo} alt="..." />
            <h6>Задачи</h6>
          </a>
        </div>
      </>
    );
  else return <Login />;
}
export default Menu;
