import React, { useState, useCallback } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { WorkingDay, Header } from "../Components";
import useAxios from "axios-hooks";

// const Works = async () => {
//   const [{data,loading, error}] = useAxios(`${process.env.REACT_APP_BACKEND_URL}/work/work/`);
//   if (loading) return (<h1>Loading..</h1>);
//   if (error) return (<h1>Error.</h1>);
//   let minDate = new Date();
//   let maxDate = new Date(0);
//   if (data.length)
//   return (<)
// }
function Works() {
  const [works, setworkItems] = useState({});
  const [loading, setLoading] = useState(false);
  const { month: routeMonth, year: routeYear } = useParams();
  console.log(routeMonth, routeYear)
  const [month, setmonth] = useState(routeMonth ? routeMonth * 1 : new Date().getMonth() + 1);
  const [year, setyear] = useState(routeYear ? routeYear * 1 : new Date().getFullYear());
  console.log(month, year)
  const history = useHistory();
  history.listen = (e) => {
    console.log(e)
  }

  const [, executeGet] = useAxios(
    { url: `${process.env.REACT_APP_BACKEND_URL}/work/work/`, method: "GET" },
    {
      manual: true,
    }
  );
  const getWorks = useCallback(() => {
    setLoading(true);
    executeGet({ params: { format: "json", month: month, year: year } })
      .then((r) => {
        let worksItems = {};
        var options = { year: "numeric", month: "long", day: "2-digit" };//////////////new Intl.DateTimeFormat("ru", { year: "numeric", month: "long" }).format();
        var dateTimeFormat = new Intl.DateTimeFormat("ru", options);
        r.data.forEach((work) => {
          const workTime = new Date(work.datestart);
          const keyDate = dateTimeFormat.format(workTime);
          if (worksItems[keyDate]) worksItems[keyDate].push(work);
          else {
            worksItems[keyDate] = [];
            worksItems[keyDate].push(work);
          }
        });
        // console.log(worksItems);
        setworkItems((prevWorks) => {
          // return { ...prevWorks, ...worksItems };
          return { ...worksItems };
        });
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.response);
      });
  }, [month, year, executeGet]);
  React.useEffect(() => {
    getWorks();
  }, [getWorks]);

  const setDate = (reverse = false) => {
    // if (!reverse)
    //   if (month === 1) {
    //     setmonth(12);
    //     setyear((y) => y - 1);
    //   } else {
    //     setmonth((m) => m - 1);
    //   }
    // else if (month === 12) {
    //   setmonth(1);
    //   setyear((y) => y + 1);
    // } else {
    //   setmonth((m) => m + 1);
    // }
    if (!reverse)
      if (month === 1) {
        setmonth(12);
        setyear((y) => y - 1);
        history.replace(`/works/${12}/${year * 1 - 1}`)
      } else {
        setmonth((m) => m - 1);
        history.replace(`/works/${month * 1 - 1}/${year}`)
      }
    else if (month === 12) {
      setmonth(1);
      setyear((y) => y + 1);
      history.replace(`/works/${1}/${year * 1 + 1}`)
    } else {
      setmonth((m) => m + 1);
      history.replace(`/works/${month * 1 + 1}/${year}`)
    }
  };
  // const loadWorks = () => {
  //   Axios.get(`${process.env.REACT_APP_BACKEND_URL}/work/work/`, {
  //     params: { format: "json", month: month, year: year },
  //   })
  //     .then((r) => {
  //       console.log(r.data);
  //       let worksItems = {};
  //       var options = { year: "numeric", month: "long", day: "2-digit" };
  //       var dateTimeFormat = new Intl.DateTimeFormat("ru", options);
  //       r.data.forEach((work) => {
  //         const workTime = new Date(work.datestart);
  //         const keyDate = dateTimeFormat.format(workTime);
  //         if (worksItems[keyDate]) worksItems[keyDate].push(work);
  //         else {
  //           worksItems[keyDate] = [];
  //           worksItems[keyDate].push(work);
  //         }
  //       });
  //       setworkItems({...works, ...worksItems});
  //       if (month === 0) {
  //         setmonth(11);
  //         setyear(year - 1);
  //       } else {
  //         setmonth(month - 1);
  //       }
  //     })
  //     .catch((e) => {
  //       if(e.response.status === 401){
  //         Cookies.remove('token');
  //         window.location.href='/';
  //       }
  //     });
  // };

  // React.useEffect(() => {
  //   loadWorks();
  // }, []);

  return (
    <>
      <Header title="Список работ" />
      <div className="paggination-layout">
        <button onClick={() => setDate()} className="btn btn-light mr-2">
          Пред. месяц
        </button>
        <h6>{new Intl.DateTimeFormat("ru", { year: "numeric", month: "long" }).format(new Date(year, month - 1))}</h6>
        <button onClick={() => setDate(true)} className="btn btn-light ml-2">
          След. месяц
        </button>
      </div>
      {loading ?
        (
          <div className="works-loading">
            <div className="spinner-border " role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )
        :
        (
          <div className="accordion" id="accordionExample">
            <Link to="/add-work" params={{ kek: "lol" }}>
              <button className="circle-button">
                <i className="fa fa-plus fa-2x icon-" aria-hidden="true"></i>
              </button>
            </Link>
            {
              Object.keys(works).length > 0 ?
                Object.keys(works).map((date, index) => (
                  <WorkingDay
                    date={date}
                    works={works[date]}
                    index={index}
                    key={`${date}_${index}`}
                  />
                )) : <h4 className="no-works mt-3">Работы в этом месяце отсутствуют.</h4>
            }
            <div className="paggination-layout"></div>
          </div >
        )
      }

    </>
  );
}
export default Works;
