import React from 'react';
import WorkItem from './WorkItem';
import cn from 'classnames';
function WorkingDay({date, index, works}){
    return(
        <div className={cn("card",{'not-full-work': works.find(item => item.full === false) || works.find(item => item.errorones !== '') })}>
        <div className="card-header" id={`heading${index}`}>
          <h2 className="mb-0">
            <button className="btn btn-works" type="button" data-toggle="collapse" data-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
              {date}
            </button>
          </h2>
        </div>
    
        <div id={`collapse${index}`} className="collapse" aria-labelledby={`heading${index}`} data-parent="#accordionExample">
          <div className="card-body">
            {works && 
            works.map((work, index)=> (
              <WorkItem company={work.companyname} full={work.full === false || work.errorones !== ''} id={work.id} startTime={work.datestart} endTime={work.dateend} description={work.description} key={`${work.company}_${index}`}  />
            )
            )
            }
            </div>
          </div>
        </div>
    )
}
export default WorkingDay;