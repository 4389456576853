import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

function WorkItem({ company, startTime, endTime, description, id, full }) {
  let options = { hour: "numeric", minute: "numeric" };
  var dateTimeFormat = new Intl.DateTimeFormat("ru", options);
  const dateStart = dateTimeFormat.format(new Date(startTime));
  let dateEnd = '';
  if (endTime) dateEnd = dateTimeFormat.format(new Date(endTime));
  else dateEnd = '--:--';

  return (
    <Link
      to={{
        pathname: "/edit-work",
        state: { id: id },
      }}
      className={cn("card work-item",{"not-full-work" : full})}
    >
      <div>{company}</div>
      <div>
        с <b>{dateStart}</b> до <b>{dateEnd}</b>
      </div>
      <div className="description">{description}</div>
    </Link>
  );
}

export default WorkItem;
