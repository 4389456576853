import React from "react";
import useAxios from "axios-hooks";
import SelectSearch from "react-select-search";
import cn from "classnames";

import "../../live-search.css";

function ObjectList({
  onObjectList,
  company,
  children,
  setErrors,
  errors,
  selectValue,
}) {
  const selectOnChange = (e) => {
    setErrors({ ...errors, object: false });
    onObjectList(e);
  };

  let options = [];
  const [{ data, loading, error }] = useAxios(
    `${process.env.REACT_APP_BACKEND_URL}/work/company/${company}/object/?format=json`
  );
  if (!loading && !error)
    options = data?.map((r) => {
      return { value: r.id, name: r.name };
    });
  if (error) {
    options = [];
  }


  if (options.length > 0)
    return (
      <div>
        <label htmlFor="contract">Подразделение:</label>
        <SelectSearch
          search
          value={selectValue}
          options={options}
          onChange={selectOnChange}
          className={cn("select-search", { input_warning: errors.object })}
          placeholder="..."
          id="contract"
        />
        {children}
      </div>
    );
  else return children;
}

function ObjectSelect({
  idcompany,
  onObjectChange,
  children,
  showError,
  errors,
  selectValue,
  setIsLoad,
  isLoad,
}) {
  if (idcompany !== null) {
    return (
      <ObjectList
        company={idcompany}
        onObjectList={onObjectChange}
        setErrors={showError}
        errors={errors}
        selectValue={selectValue}
        isLoad={isLoad}
        setIsLoad={setIsLoad}
      >
        {children}
      </ObjectList>
    );
  } else {
    return null;
  }
}

export default ObjectSelect;
