import React from "react";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import useAxios from "axios-hooks";
import { Header } from "../Components";

function Status() {
  const compare = (a, b) => {
    const userA = a.user.toUpperCase();
    const userB = b.user.toUpperCase();

    let comparison = 0;
    if (userA > userB) {
      comparison = 1;
    } else if (userA < userB) {
      comparison = -1;
    }
    return comparison;
  };
  const [{ data, loading, error }] = useAxios(
    `${process.env.REACT_APP_BACKEND_URL}/work/state/all`
  );
  let options = {
    //   year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
  };
  let dateTimeFormat = new Intl.DateTimeFormat("ru", options);
  if (loading) return <h1>Loading..</h1>;
  if (error) return <h1>Error.</h1>;
  const styleKek = {
    height: "100%",
    width: "100%",
  };
  data.sort(compare);
  // setTimeout(() => {
  //   ExecuteReq()
  //     .then((r) => {
  //       console.log(r);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }, 5000);
  return (
    <div style={styleKek}>
      <Header title="Занятость сотрудников" />
      <div className="status-page">
        <Table striped bordered>
          <thead>
            <tr>
              <th>Сотрудник</th>
              <th>Статус</th>
              <th>Последнее изменение</th>
              <th>Клиент</th>
              <th>Комментарий</th>
            </tr>
          </thead>
          <tbody>
            {data.map((employer, index) => (
              <tr key={`${employer}_${index}`}>
                <td>{employer.user}</td>
                <td>{employer.name_ru}</td>
                <td>{dateTimeFormat.format(new Date(employer.timeedit))}</td>
                <td>{employer.company}{employer.companyobject_name && `, ${employer.companyobject_name}`}</td>
                <td>{employer.destination}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="status-page-card">
        {data.map((employer, index) => (
          <Card
            key={`${employer}_${index}`}
            border="secondary"
            style={{
              width: "auto",
              margin: "0.5rem",
            }}
          >
            <Card.Header>{employer.user}</Card.Header>
            <Card.Body>
              <Card.Text>
                <b>Статус:</b> {employer.name_ru}
                <br />
                <b>Последнее изменение:</b>{" "}
                {dateTimeFormat.format(new Date(employer.timeedit))}
                <br />
                {employer.company && (
                  <>
                    <b>Клиент:</b> {employer.company}{employer.companyobject_name && `, ${employer.companyobject_name}`}
                    <br />
                  </>
                )}
                {employer.destination && (
                  <>
                    <b>Комментарий:</b> {employer.destination}
                    <br />
                  </>
                )}
              </Card.Text>
            </Card.Body>
          </Card>
        ))}
      </div>
    </div>
  );
}

export default Status;
