import React from 'react';
import { Route } from 'react-router-dom';
import { Works, Menu, AddWork, Arrive, Test, Status } from './Pages'
import { Login } from './Components'
import axios from 'axios';
import Cookies from "js-cookie";


function App() {
  axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 401) {
      Cookies.remove('token');
      setTimeout(()=>window.location.href = "/login", 250)
        ;
    }
    return Promise.reject(error);
  });
  // React.useEffect(() => {
  //   axios.get('https://api.avpkirov.ru/work/state').catch(e => {
  //     if (e.response.status === 401) {
  //       Cookies.remove('token');
  //       if (Cookies.get('token'))
  //         window.location.href = "/login";
  //     }
  //     if (e.response.status === 404) {
  //       axios.post('https://api.avpkirov.ru/work/state', { name: "null" })
  //     }
  //   })
  // }, [])
  return (
    <div>
      <Route path="/" component={Menu} exact />
      <Route path="/arrives" component={Arrive} exact />
      <Route path="/works/:month?/:year?" render={() => <Works />} exact />
      <Route path="/add-work" component={AddWork} exact />
      <Route path="/test" component={Test} exact />
      <Route path="/edit-work" component={AddWork} exact />
      <Route path="/edit-work-back-to-journal" component={AddWork} exact />
      <Route path="/add-remote-work" component={AddWork} exact />
      <Route path="/status" component={Status} exact />
      <Route path="/login" component={Login} exact />
    </div>
  );
}

export default App;
