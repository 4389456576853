import React, { useRef } from "react";
import useAxios from "axios-hooks";
import SelectSearch from "react-select-search";
import cn from "classnames";

import "../../live-search.css";
const companysFromLS = JSON.parse(localStorage.getItem("companysSelected"));
const compareCompany = (a, b) => {
  let r = 0;
  if (
    companysFromLS.hasOwnProperty(a.value) &&
    !companysFromLS.hasOwnProperty(b.value)
  ) {
    r = -1;
  } else if (
    !companysFromLS.hasOwnProperty(a.value) &&
    companysFromLS.hasOwnProperty(b.value)
  ) {
    r = 1;
  } else if (
    companysFromLS.hasOwnProperty(a.value) &&
    companysFromLS.hasOwnProperty(b.value)
  ) {
    r = companysFromLS[a.value] > companysFromLS[b.value] ? -1 : 1;
  }
  return r;
};

function CompanySelect({ onCompanyChange, showError, errors, selectValue }) {
  const changeSelect = (e) => {
    showError({ ...errors, company: false });
    onCompanyChange(e);
  };
  let options = [];
  let selectorRef = useRef();
  const [{ data, loading, error }] = useAxios(
    `${process.env.REACT_APP_BACKEND_URL}/work/company/?format=json`
  );
  if (!loading && !error) {
    options = data?.map((r) => {
      return {
        value: r.idcompany,
        name: r.name,
      };
    });
    if (companysFromLS !== null) options.sort(compareCompany);
  }
  if (error) {
    options = [];
  }

  return (
    <div>
      <SelectSearch
        ref={selectorRef}
        id="company"
        search
        onChange={changeSelect}
        className={cn("select-search", { input_warning: errors.company })}
        options={options}
        value={selectValue}
        placeholder="Выберите контрагента"
      />
    </div>
  );
}

export default CompanySelect;
