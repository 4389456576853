import React, { useRef } from "react";
import useAxios from "axios-hooks";
import SelectSearch from "react-select-search";
import cn from "classnames";

const contractsFromLS = JSON.parse(localStorage.getItem("contractsSelected"));
const compareContract = (a, b) => {
  let r = 0;
  if (
    contractsFromLS.hasOwnProperty(a.value) &&
    !contractsFromLS.hasOwnProperty(b.value)
  ) {
    r = -1;
  } else if (
    !contractsFromLS.hasOwnProperty(a.value) &&
    contractsFromLS.hasOwnProperty(b.value)
  ) {
    r = 1;
  } else if (
    contractsFromLS.hasOwnProperty(a.value) &&
    contractsFromLS.hasOwnProperty(b.value)
  ) {
    r = contractsFromLS[a.value] > contractsFromLS[b.value] ? -1 : 1;
  }
  return r;
};

function ContractList({
  onContractList,
  company,
  setO,
  setErrors,
  errors,
  selectValue,
}) {
  let selectRef = useRef();
  let options = [];
  const selectOnChange = (e) => {
    setO(options.find((contract) => contract.value === e).mustHave);
    setErrors({ ...errors, contract: false });
    onContractList(e);
  };
  const [{ data, loading, error }] = useAxios(
    `${process.env.REACT_APP_BACKEND_URL}/work/company/${company}/contract/?format=json`
  );
  if (!loading && !error) {
    options = data.map((r) => {
      return { value: r.id, name: r.name, mustHave: r.o };
    });
    if (contractsFromLS !== null) options.sort(compareContract);
  }
  if (error) {
    options = [];
  }
  return (
    <div>
      <label htmlFor="contract">Договор</label>
      <SelectSearch
        search
        ref={selectRef}
        options={options}
        onChange={selectOnChange}
        placeholder="..."
        value={selectValue}
        className={cn("select-search", { input_warning: errors.contract })}
        id="contract"
      />
    </div>
  );
}

function ContractsSelect({
  onContractChange,
  idcompany,
  objIsMustHave,
  showError,
  errors,
  selectValue,
  setIsLoad,
  isLoad,
}) {
  // const [companyId, setstate] = useState(0);
  // React.useEffect(setstate(idcompany),[idcompany])
  if (idcompany !== null) {
    return (
      <ContractList
        company={idcompany}
        setO={objIsMustHave}
        onContractList={onContractChange}
        setErrors={showError}
        errors={errors}
        selectValue={selectValue}
        isLoad={isLoad}
        setIsLoad={setIsLoad}
      />
    );
  } else {
    return (
      <div>
        <label htmlFor="inputState">Договор</label>
        <SelectSearch
          options={[]}
          className="select-search"
          placeholder="..."
          id="contract"
        />
      </div>
    );
  }
}

export default ContractsSelect;
