import React, { useState } from "react";
import useAxios from "axios-hooks";
import Cookies from "js-cookie";

function Login() {
  const [loginCorrectly, setloginCorrectly] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [_, executeLogin] = useAxios(
    { url: `${process.env.REACT_APP_BACKEND_URL}/work/login`, method: "POST" },
    { manual: true }
  );
  const submit = (e) => {
    e.preventDefault();
    executeLogin({
      data: {
        username: e.target.elements.login.value,
        password: e.target.elements.password.value,
      },
    })
      .then((r) => {
        console.log(r, "rtr");
        Cookies.set("token", r.data.token, { expires: 365 });
        window.location.href = "/";
      })
      .catch((e) => {
        console.log(e);
        if (e.response.request.status === 401) setloginCorrectly(true);
      });
  };

  const hideErrorLabel = () => {
    setloginCorrectly(false);
  };
  return (
    <form className="container" onSubmit={submit}>
      <h5 className="text-center"> Авторизация</h5>
      <input
        type="text"
        placeholder="Логин"
        name="login"
        className="form-control"
        onChange={hideErrorLabel}
      />
      <input
        type="password"
        placeholder="Пароль"
        name="password"
        className="form-control"
        onChange={hideErrorLabel}
      />
      <button className="btn btn-login" type="submit">
        Войти
      </button>
      {loginCorrectly && (
        <label className="error-label">Введены неверные учетные данные</label>
      )}
    </form>
  );
}

export default Login;
