import axios from "axios";

export async function makeReq(method, url, options = {}) {
    console.log(options);
    if (method.toUpperCase() === "GET") {
        const { data: GETres } = await axios.get(url, { params: { ...options } });
        return GETres;
    } else if (method.toUpperCase() === "POST") {
        const { data: POSTres } = await axios.post(url, options);
        return POSTres;
    }
    throw new SyntaxError("Данные некорректны method =" + method);
}

const getLocation = async (options) => {
    return new Promise((res, rej) => {
        navigator.geolocation.getCurrentPosition(res, rej, options);
    });
};

export async function getPosition() {
    let location = {};
    try {
        location = await getLocation();
    } catch (err) {
        location = err;
    }
    let position = {};
    if (!location.code) {
        //   const { data: geo } = await axios.post(
        //     "https://api.avpkirov.ru/work/map",
        //     {
        //       lat: location.coords.latitude,
        //       lon: location.coords.longitude,
        //     }
        //   );
        const geo = await makeReq("POST", "https://api.avpkirov.ru/work/map", {
            lat: location.coords.latitude,
            lon: location.coords.longitude,
        });
        position = {
            lat: location.coords.latitude,
            lon: location.coords.longitude,
            map:
                geo.address.road +
                " " +
                geo.address.house_number +
                " " +
                geo.address.city,
        };
        // console.log(position.lat + "," + position.long);
    } else {
        position = { lat: 0, lon: 0, map: "Неизвестно" };
    }
    return position;
}