import React, { useState, useRef } from "react";
// import SelectSearch from "react-select-search";
// import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import useAxios from "axios-hooks";
import { Header, Login } from "../Components";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import SelectSearch from "react-select-search";
import "../modal.css";

import { getPosition, makeReq } from "../Utilities/utility";

// imgs
import remoteLogo from "../assets/img/remote-work.png";
import goToHomeLogo from "../assets/img/go-to-home.png";
import officeLogo from "../assets/img/office-work.png";
import departureLogo from "../assets/img/departure-work.png";
import workAtHomeLogo from "../assets/img/work-at-home.png";
import endLogo from "../assets/img/end-work.png";
import dinnerLogo from "../assets/img/dinner.png";
import dinnerBackLogo from "../assets/img/dinner-back.png";
import cancelLogo from "../assets/img/cancel-work.png";
import arrivedLogo from "../assets/img/arrived.png";
import goToOfficeLogo from "../assets/img/have-going-to-office.png";

const inOfficeStates = ["null", "shiftStarting", "dinner", "haveGoingToOffice"];

const companysFromLS = JSON.parse(localStorage.getItem("companysSelected"));
const compareCompany = (a, b) => {
  let r = 0;
  if (
    companysFromLS.hasOwnProperty(a.value) &&
    !companysFromLS.hasOwnProperty(b.value)
  ) {
    r = -1;
  } else if (
    !companysFromLS.hasOwnProperty(a.value) &&
    companysFromLS.hasOwnProperty(b.value)
  ) {
    r = 1;
  } else if (
    companysFromLS.hasOwnProperty(a.value) &&
    companysFromLS.hasOwnProperty(b.value)
  ) {
    r = companysFromLS[a.value] > companysFromLS[b.value] ? -1 : 1;
  }
  return r;
};
// const statesList = {
//   departing: "Выехал",
//   arrived: "Прибыл",
//   startWork: "Работа у клиента",
//   endWork: "Закончил работу у клиента",
//   remoteWork: "Удаленная работа",
//   inOffice: "Офис",
//   shiftStarting: "Начал смену",
//   dinner: "Обед",
//   null: "Неизвестно",
// };
const getState = async () => {
  const state = await makeReq("GET", `${process.env.REACT_APP_BACKEND_URL}/work/state`);
  return state;
};
const setState = async (
  name,
  position,
  company = null,
  object = null,
  destination = null,
  work = null
) => {
  // const res =
  await makeReq("POST", `${process.env.REACT_APP_BACKEND_URL}/work/state`, {
    name: name,
    company: company,
    companyobject: object,
    destination: destination,
    work: work,
    ...position,
  });
  // if (res.error)
  // console.log(r.data.status);
};

const Arrive = () => {
  const [{ data, loading, error }] = useAxios(
    `${process.env.REACT_APP_BACKEND_URL}/work/state`
  );
  if (loading) return <h1>Loading...</h1>;
  if (error) {
    if (error.response.status !== 404) return <h1>Error.</h1>;
    else
      setState("null", null, null, null, null, {
        lat: 0,
        lon: 0,
        map: "Неизвестно",
      });
  }
  return <WorkEvents startState={error ? "null" : data.name} />;
};

function WorkEvents({ startState }) {
  console.log(process.env.REACT_APP_BACKEND_URL);
  const [stateJournal, setstateJournal] = useState(startState);
  const [show, setShow] = useState(false);
  // const [showCancel, setShowCancel] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const handleClose = () => {
    setShow(false);
    window.location.href = "/arrives";
  };
  const handleCloseConfirm = () => setShowConfirm(false);
  // const handleCloseCancel = () => setShowCancel(false);
  const [options, setcompanyList] = useState([]);
  const [optionsObject, setobjectList] = useState([]);
  const textModal = useRef();
  const timeModal = useRef();
  const clientTimeEvent = useRef();
  const clickState = useRef(startState);
  // const clientTime = useRef();
  // const reason = useRef("");
  const idCompany = useRef(null);
  const idObject = useRef(null);

  const changeCompany = (e) => {
    console.log(e);
    idCompany.current = e;
    idObject.current = null;
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/work/company/${e}/object/?format=json`)
      .then((r) => {
        let options = r.data.map((r) => {
          return { value: r.id, name: r.name };
        });
        setobjectList(options);
      })
      .catch((e) => {
        setobjectList([]);
      });
  };

  const changeObject = (e) => {
    idObject.current = e;
  };

  const clickMenuItemHandler = (e) => {
    clickState.current = e.target.getAttribute("state");
    setShowConfirm(true);
  };

  const handleSaveDepart = async (e) => {
    e.persist();
    e.target.disabled = true;
    const position = await getPosition();
    const cancelState = await getState();
    if (clickState.current === "shiftStarting") {
      if (cancelState.name === "arrived" || cancelState.name === "startWork")
        await makeReq("POST", `${process.env.REACT_APP_BACKEND_URL}/work/visit`, {
          datetime: clientTimeEvent.current,
          outtype: true,
          company: cancelState.company,
          destination: cancelState.destination,
          ...position,
        });
      let workCancel = {
        datestart: cancelState.timeedit,
        // dateend: new Date(),
        dateend: clientTimeEvent.current,
        company: cancelState.company,
        companyobject: cancelState.companyobject,
        description:
          cancelState.destination +
          ` (Вызов отменён, причина: ${textModal.current.value})`,
        full: false,
        comment: `Вызов отменён`,
      };
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/work/work`, workCancel);
      await setState("shiftStarting", position);
    } else if (clickState.current === "departing") {
      // if (cancelState.name === "inOffice")
      //   await makeReq("POST", `${process.env.REACT_APP_BACKEND_URL}/work/visit`, {
      //     datetime: clientTimeEvent.current,
      //     outtype: true,
      //     office: true,
      //     ...position,
      //   });
      // else
      // if (cancelState.name === "dinner")
      //   await makeReq("POST", `${process.env.REACT_APP_BACKEND_URL}/work/visit`, {
      //     datetime: clientTimeEvent.current,
      //     outtype: true,
      //     lanch: true,
      //     ...position,
      //   });
      // console.log(idCompany.current);
      await makeReq("POST", `${process.env.REACT_APP_BACKEND_URL}/work/visit`, {
        datetime: clientTimeEvent.current,
        company: idCompany.current,
        destination: idObject.current,
        outtype: true,
        ...position,
      });
      await setState(
        stateJournal,
        position,
        idCompany.current,
        idObject.current,
        textModal.current.value
      );
      idCompany.current = null;
      idObject.current = null;
    }
    // else if (clickState.current === "remoteWork") {
    //   console.log("remoteWork");
    //   let typesWork = await makeReq(
    //     "GET",
    //     `${process.env.REACT_APP_BACKEND_URL}/work/worktype`
    //   );
    //   let workNewElement = await makeReq(
    //     "POST",
    //     `${process.env.REACT_APP_BACKEND_URL}/work/work`,
    //     {
    //       datestart: clientTimeEvent.current,
    //       company: idCompany.current,
    //       companyobject: idObject.current,
    //       type: typesWork.find((x) => x.name === "Удаленная работа")?.id,
    //       description:
    //         textModal.current.value !== null ? "!" : textModal.current.value,
    //       full: false,
    //     }
    //   );
    //   await setState(
    //     stateJournal,
    //     position,
    //     idCompany.current,
    //     idObject.current,
    //     textModal.current.value,
    //     workNewElement.id
    //   );
    //   idCompany.current = null;
    //   idObject.current = null;
    // }
    handleClose();
    e.target.disabled = false;
    textModal.current.value = "";
    //сохранить выезд
  };
  const confirmHandler = async (e) => {
    e.persist();
    e.target.disabled = true;
    clientTimeEvent.current = timeModal.current.value;
    const position = await getPosition();
    switch (clickState.current) {
      case "null":
        setState(clickState.current, position);
        break;
      case "inOffice":
        // let stateInOffice = await getState();
        // убрал outtype при возврате с обеда в офис
        // if (stateInOffice.name === "dinner")
        //   makeReq("POST", `${process.env.REACT_APP_BACKEND_URL}/work/visit`, {
        //     outtype: true,
        //     lanch: true,
        //     datetime: timeModal.current.value,
        //     ...position,
        //   });
        makeReq("POST", `${process.env.REACT_APP_BACKEND_URL}/work/visit`, {
          intype: true,
          office: true,
          datetime: timeModal.current.value,
          ...position,
        });
        setState("inOffice", position);
        break;

      case "remoteWork":
        window.location.href = "/add-remote-work";
        // axios
        //   .get(`${process.env.REACT_APP_BACKEND_URL}/work/company/?format=json`)
        //   .then((r) => {
        //     let option = [];
        //     option = r.data.map((element) => {
        //       return { value: element.idcompany, name: element.name };
        //     });
        //     if (companysFromLS !== null) option.sort(compareCompany);
        //     setcompanyList(option);
        //   })
        //   .catch((e) => {
        //     setcompanyList([]);
        //     console.log(e);
        //   });
        // setShow(true);
        break;

      case "departing":
        axios
          .get(`${process.env.REACT_APP_BACKEND_URL}/work/company/?format=json`)
          .then((r) => {
            let option = [];
            option = r.data.map((element) => {
              return { value: element.idcompany, name: element.name };
            });
            if (companysFromLS !== null) option.sort(compareCompany);
            setcompanyList(option);
          })
          .catch((e) => {
            setcompanyList([]);
            console.log(e);
          });
        setShow(true);
        break;

      case "arrived":
        const oldState = await getState();
        await makeReq("POST", `${process.env.REACT_APP_BACKEND_URL}/work/visit`, {
          datetime: clientTimeEvent.current,
          intype: true,
          company: oldState.company,
          destination: oldState.destination,
          ...position,
        });
        // let now = new Date();
        let now = clientTimeEvent.current;
        let wayTime = (now - new Date(oldState.timeedit)) / 60000;
        let workItem = {
          datestart: now,
          company: oldState.company,
          companyobject: oldState.companyobject,
          description:
            oldState.destination !== null ? "!" : oldState.destination,
          full: false,
          timeroad: Math.round(wayTime),
        };
        const { data: newWork } = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/work/work`,
          workItem
        );
        await setState(
          clickState.current,
          position,
          oldState.company,
          oldState.companyobject,
          oldState.destination,
          newWork.id
        );
        break;

      case "haveGoingToOffice":
        await makeReq("POST", `${process.env.REACT_APP_BACKEND_URL}/work/visit`, {
          datetime: clientTimeEvent.current,
          outtype: true,
          office: true,
          ...position,
        });
        setState("haveGoingToOffice", position);
        break;

      case "startWork":
        let startTime = clientTimeEvent.current;
        const stateBeforeStart = await getState();
        if (stateBeforeStart.name === "departing") {
          await makeReq("POST", `${process.env.REACT_APP_BACKEND_URL}/work/visit`, {
            datetime: clientTimeEvent.current,
            intype: true,
            company: stateBeforeStart.company,
            destination: stateBeforeStart.destination,
            ...position,
          });
        }
        let typesWork = await makeReq(
          "GET",
          `${process.env.REACT_APP_BACKEND_URL}/work/worktype`
        );
        let workItemStart = {
          datestart: startTime,
          company: stateBeforeStart.company,
          companyobject: stateBeforeStart.companyobject,
          type: typesWork.find((x) => x.name === "Выезд к клиенту")?.id,
          description:
            stateBeforeStart.destination !== null
              ? "!"
              : stateBeforeStart.destination,
          full: false,
        };
        let workState = {};
        if (stateBeforeStart.work === null) {
          let wayTime =
            (startTime - new Date(stateBeforeStart.timeedit)) / 60000;
          workItemStart.timeroad = Math.round(wayTime);
          const { data: newWorkStart } = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/work/work`,
            workItemStart
          );
          workState = newWorkStart;
        } else {
          const { data: currentWork } = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/work/work/${stateBeforeStart.work}`
          );
          workState = currentWork;
          workState.datestart = startTime;

          axios
            .put(
              `${process.env.REACT_APP_BACKEND_URL}/work/work/${stateBeforeStart.work}`,
              workState
            )
            .catch(function (error) {
              console.log(error.response);
              alert(error.response.data[Object.keys(error.response.data)[0]]);
            });
        }
        await setState(
          clickState.current,
          position,
          stateBeforeStart.company,
          stateBeforeStart.companyobject,
          stateBeforeStart.destination,
          workState.id
        );
        break;

      case "endWork":
        // clickState.current = "shiftStarting";
        let endTime = clientTimeEvent.current;
        const stateBeforeEnd = await getState();
        // await makeReq("POST", `${process.env.REACT_APP_BACKEND_URL}/work/visit`, {
        //   datetime: clientTimeEvent.current,
        //   outtype: true,
        //   office: true,
        //   ...position,
        // });
        const { data: currentWorkEnd } = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/work/work/${stateBeforeEnd.work}`
        );
        let workItemEndTime = currentWorkEnd;
        workItemEndTime.dateend = endTime;
        axios
          .put(
            `${process.env.REACT_APP_BACKEND_URL}/work/work/${stateBeforeEnd.work}`,
            workItemEndTime
          )
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error.response);
            alert(error.response.data[Object.keys(error.response.data)[0]]);
          });
        await setState("endWork", position);
        window.location.href = `/edit-work-back-to-journal?${stateBeforeEnd.work}`;
        break;

      case "cancelReq":
        clickState.current = "endWork";
        break;
      case "dinner":
        // const lastState = await getState();
        // console.log(lastState);
        // убрал outtype при выезде из офисе на обед
        // if (lastState.name === "inOffice")
        //   await makeReq("POST", `${process.env.REACT_APP_BACKEND_URL}/work/visit`, {
        //     outtype: true,
        //     office: true,
        //     datetime: timeModal.current.value,
        //     ...position,
        //   });
        makeReq("POST", `${process.env.REACT_APP_BACKEND_URL}/work/visit`, {
          intype: true,
          lanch: true,
          datetime: timeModal.current.value,
          ...position,
        });
        setState("dinner", position);
        break;
      default:
        await setState(clickState.current, position);
    }
    if (clickState.current !== "remoteWork")
      setstateJournal(clickState.current);
    // setstateJournal(clickState.current);
    e.target.disabled = false;
    setShowConfirm(false);
  };
  const cancelClickHandler = () => {
    setShowConfirm(false);
  };
  const modalConfirmOnShow = () => {
    timeModal.current.defaultValue = new Date(
      new Date().setHours(
        new Date().getHours() + Math.abs(new Date().getTimezoneOffset() / 60)
      )
    )
      .toJSON()
      .slice(0, 16);
  };
  // const handleCancel = async () => {
  //   handleClose();
  // };

  if (Cookies.get("token"))
    return (
      <>
        <div className="list-group menu-list-group">
          <Header title="Журнал передвижений" />
          {/* <div className="list-group-item list-group-item-action active">
            Журнал передвижений
          </div> */}
          {(stateJournal === "null" || stateJournal === "goToHome" || stateJournal === "dinner") && (
            <div
              onClick={clickMenuItemHandler}
              state="workAtHome"
              className="list-group-item list-group-item-action"
            >
              <img
                state="workAtHome"
                src={workAtHomeLogo}
                alt="remote-logo"
                className="arrive-logos"
              />
              <h6 state="workAtHome">Работа на дому</h6>
            </div>
          )}
          {(stateJournal === "endWork" || stateJournal === "haveGoingToOffice") && (
            <div
              onClick={clickMenuItemHandler}
              state="goToHome"
              className="list-group-item list-group-item-action"
            >
              <img
                state="goToHome"
                src={goToHomeLogo}
                alt="remote-logo"
                className="arrive-logos"
              />
              <h6 state="goToHome">Еду домой</h6>
            </div>
          )}
          {inOfficeStates.includes(stateJournal) && (
            <div
              onClick={clickMenuItemHandler}
              state="inOffice"
              className="list-group-item list-group-item-action"
            >
              <img
                state="inOffice"
                src={officeLogo}
                alt="remote-logo"
                className="arrive-logos"
              />
              <h6 state="inOffice">В офисе</h6>
            </div>
          )}
          {(stateJournal === "departing" || stateJournal === "arrived") && (
            <div>
              {stateJournal !== "arrived" && (
                <div
                  onClick={clickMenuItemHandler}
                  state="arrived"
                  className="list-group-item list-group-item-action"
                >
                  <img
                    state="arrived"
                    src={arrivedLogo}
                    alt="remote-logo"
                    className="arrive-logos"
                  />
                  <h6 state="arrived">Прибыл к клиенту</h6>
                </div>
              )}

              <div
                onClick={clickMenuItemHandler}
                state="startWork"
                className="list-group-item list-group-item-action"
              >
                <svg
                  state="startWork"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="play"
                  className="svg-inline--fa fa-play fa-w-14 arrive-logos"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    state="startWork"
                    fill="currentColor"
                    d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
                  ></path>
                </svg>
                <h6 state="startWork">Начать работу</h6>
              </div>
              <div
                onClick={clickMenuItemHandler}
                state="cancelReq"
                className="list-group-item list-group-item-action"
              >
                <img
                  state="cancelReq"
                  src={cancelLogo}
                  alt="remote-logo"
                  className="arrive-logos"
                />
                <h6 state="cancelReq">Отмена вызова</h6>
              </div>
            </div>
          )}
          {stateJournal !== "departing" &&
            stateJournal !== "startWork" &&
            stateJournal !== "arrived" &&
            stateJournal !== "remoteWork" && (
              <div
                onClick={clickMenuItemHandler}
                state="departing"
                className="list-group-item list-group-item-action"
              >
                <img
                  state="departing"
                  src={departureLogo}
                  alt="remote-logo"
                  className="arrive-logos"
                />
                <h6 state="departing">Выехал к клиенту</h6>
              </div>
            )}
          {stateJournal !== "remoteWork" && (
            <div
              onClick={clickMenuItemHandler}
              state="remoteWork"
              className="list-group-item list-group-item-action"
            >
              <img
                state="remoteWork"
                src={remoteLogo}
                alt="remote-logo"
                className="arrive-logos"
              />
              <h6 state="remoteWork">Удаленная работа</h6>
            </div>
          )}
          {(stateJournal === "startWork" || stateJournal === "remoteWork") && (
            <div
              onClick={clickMenuItemHandler}
              state="endWork"
              className="list-group-item list-group-item-action"
            >
              <svg
                state="endWork"
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="hand-paper"
                className="svg-inline--fa fa-hand-paper fa-w-14 arrive-logos"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  state="endWork"
                  fill="currentColor"
                  d="M372.57 112.641v-10.825c0-43.612-40.52-76.691-83.039-65.546-25.629-49.5-94.09-47.45-117.982.747C130.269 26.456 89.144 57.945 89.144 102v126.13c-19.953-7.427-43.308-5.068-62.083 8.871-29.355 21.796-35.794 63.333-14.55 93.153L132.48 498.569a32 32 0 0 0 26.062 13.432h222.897c14.904 0 27.835-10.289 31.182-24.813l30.184-130.958A203.637 203.637 0 0 0 448 310.564V179c0-40.62-35.523-71.992-75.43-66.359zm27.427 197.922c0 11.731-1.334 23.469-3.965 34.886L368.707 464h-201.92L51.591 302.303c-14.439-20.27 15.023-42.776 29.394-22.605l27.128 38.079c8.995 12.626 29.031 6.287 29.031-9.283V102c0-25.645 36.571-24.81 36.571.691V256c0 8.837 7.163 16 16 16h6.856c8.837 0 16-7.163 16-16V67c0-25.663 36.571-24.81 36.571.691V256c0 8.837 7.163 16 16 16h6.856c8.837 0 16-7.163 16-16V101.125c0-25.672 36.57-24.81 36.57.691V256c0 8.837 7.163 16 16 16h6.857c8.837 0 16-7.163 16-16v-76.309c0-26.242 36.57-25.64 36.57-.691v131.563z"
                ></path>
              </svg>
              <h6 state="endWork">Закончить работу</h6>
            </div>
          )}
          {(stateJournal === "endWork" || stateJournal === "workAtHome" || stateJournal === "goToHome") && (
            <div
              onClick={clickMenuItemHandler}
              state="haveGoingToOffice"
              className="list-group-item list-group-item-action"
            >
              <img
                state="haveGoingToOffice"
                src={goToOfficeLogo}
                alt="remote-logo"
                className="arrive-logos"
              />
              <h6 state="haveGoingToOffice">Выехал в офис</h6>
            </div>
          )}
          {["inOffice", "shiftStarting", "endWork", "workAtHome"].includes(
            stateJournal
          ) && (
              <div>
                <div
                  onClick={clickMenuItemHandler}
                  state="dinner"
                  className="list-group-item list-group-item-action"
                >
                  <img
                    state="dinner"
                    src={stateJournal === "dinner" ? dinnerBackLogo : dinnerLogo}
                    alt="remote-logo"
                    className="arrive-logos"
                  />
                  <h6 state="dinner">Обед</h6>
                </div>
                {stateJournal !== "dinner" && (
                  <div
                    onClick={clickMenuItemHandler}
                    state={"null"}
                    className="list-group-item list-group-item-action"
                  >
                    <img
                      state={"null"}
                      src={endLogo}
                      alt="remote-logo"
                      className="arrive-logos"
                    />
                    <h6 state={"null"}>Закончить смену</h6>
                  </div>
                )}
              </div>
            )}
        </div>
        <Modal backdrop="static" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {stateJournal !== "shiftStarting"
                ? "Укажите контрагента"
                : "Укажите причину"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {stateJournal !== "shiftStarting" && (
              <>
                <SelectSearch
                  search
                  options={options}
                  onChange={changeCompany}
                  value={null}
                  placeholder="Место назначения"
                // className={cn("select-search", { input_warning: errors.type })}
                />

                <hr />
                {optionsObject.length > 0 && (
                  <>
                    <SelectSearch
                      search
                      options={optionsObject}
                      onChange={changeObject}
                      value={null}
                      placeholder="Подразделение"
                    // className={cn("select-search", { input_warning: errors.type })}
                    />
                    <hr />
                  </>
                )}
              </>
            )}
            <textarea
              ref={textModal}
              type="text"
              className="form-control"
              name="comment"
              placeholder="Комментарий"
            ></textarea>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
            <Button
              variant="primary"
              onClick={handleSaveDepart}
              className="btn btn-login mb-2"
            >
              Сохранить
            </Button>
            <Button
              variant="danger"
              onClick={handleClose}
              className="btn  btn-login mb-2 cancel-modal"
            >
              Отменить
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          backdrop="static"
          show={showConfirm}
          onHide={handleCloseConfirm}
          onShow={modalConfirmOnShow}
        >
          <Modal.Header closeButton>
            <Modal.Title>Укажите время</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              ref={timeModal}
              id="startTime"
              type="datetime-local"
              name="startTime"
              defaultValue={new Date(
                new Date().setHours(
                  new Date().getHours() +
                  Math.abs(new Date().getTimezoneOffset() / 60)
                )
              )
                .toJSON()
                .slice(0, 16)}
              className="date_input"
            />
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
            <Button
              variant="primary"
              onClick={confirmHandler}
              className="btn btn-login mb-2"
            >
              Подтвердить
            </Button>
            <Button
              variant="danger"
              onClick={cancelClickHandler}
              className="btn  btn-login mb-2 cancel-modal"
            >
              Отменить
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  else return <Login />;
}

export default Arrive;
