import React from "react";
import Axios from "axios";
import cn from "classnames";

class CharacterSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { options: [] };
  }

  componentDidMount() {
    Axios.get(`${process.env.REACT_APP_BACKEND_URL}/work/characterwork/?format=json`)
      .then((r) => {
        let option = [];
        option = r.data.map((element) => {
          return { name: element.name, id: element.id };
        });
        this.setState({ options: option });
      })
      .catch((e) => {
        // if (e.response.request.status === 401) {
        //   Cookies.remove("token");
        //   window.location.href = "/";
        // }
        this.setState({
          options: [
            { value: 1, name: "name1" },
            { value: 2, name: "name2" },
            { value: 3, name: "name3" },
          ]
        });
      });
  }

  render() {
    const onClickHandler = (e) => {
      this.props.onChangeCharacter(parseInt(e.target.id));
      if (this.props.errors.character) this.props.setErrors({...this.props.errors, character : false})
    };
    // if (!loading && !error) onChangeCharacter(data[0].id);
    return (
      <div>
        <label>Характер работ</label>
        <div
          className={cn("list-group list-group-horizontal",{input_warning : this.props.errors.character})}
          id="characterWork"
          role="tablist"
        >
          {this.state.options.length < 1 ? (
            <div
              className="list-group-item items-characters-work list-group-item-action active"
              data-toggle="list"
              role="tab"
            >
              Характеры работ не загружены
            </div>
          ) : (
            this.state.options.map((character, index) => (
              <div
                className={cn(
                  "list-group-item items-characters-work list-group-item-action",
                  { active: character.id === this.props.selectValue }
                )}
                id={character.id}
                data-toggle="list"
                role="tab"
                onClick={onClickHandler}
                key={`${character.name}_${index}`}
              >
                {character.name}
              </div>
            ))
          )}
        </div>
      </div>
    );
  }
}
export default CharacterSelect;
